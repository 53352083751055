export const subscriptionPlans = [
  // {
  //   planId: "TEST_PLAN_01",
  //   priceId: "price_1PzrhTP3zz1rfd7r4yPOuO97",
  //   title: "Test subscription",
  //   maxNoPdf: 1,
  //   maxFileSizeMB: 1, // unit MB
  //   maxMessagePerMonth: 50,
  //   price: 50,
  //   durationInDays: 15,
  //   maxAllowedDomain: 1, // each chatbot
  //   description: [
  //     "無料使用期間：15日",
  //     "チャットボット数：1",
  //     "ファイルアップロード数：1回のみ",
  //     "アップロードするPDFファイルのサイズ上限：1MB",
  //     "管理画面アクセス可",
  //     "AIモデル：基本モデル",
  //     "問合せ数：50",
  //   ],
  //   buttonVariant: "outlined",
  // },
  {
    planId: "FREE_PLAN_01",
    priceId: "",
    title: "無料プラン",
    maxNoPdf: 1,
    maxFileSizeMB: 1, // unit MB
    maxMessagePerMonth: 50,
    price: 0,
    durationInDays: 15,
    maxAllowedDomain: 0, // each chatbot
    description: [
      "無料使用期間：15日",
      "チャットボット数：1",
      "ファイルアップロード数：1回のみ",
      "アップロードするPDFファイルのサイズ上限：1MB",
      "管理画面アクセス可",
      "AIモデル：基本モデル",
      "問合せ数：50",
    ],
    buttonVariant: "outlined",
  },
  {
    planId: "BASIC_PLAN_01",
    priceId: "price_1PuUiPP3zz1rfd7rzwbfxxxy", //development: "price_1Ps1POP3zz1rfd7r08hGThzI",
    title: "Basic Plan",
    subheader: "",
    maxNoPdf: 5,
    maxFileSizeMB: 5, // Unit MB
    maxMessagePerMonth: 5000,
    price: "19,800", // Price in japanese yen
    durationInDays: 30,
    maxAllowedDomain: 1, // each chatbot
    description: [
      "チャットボット数：1",
      "PDFアップロード数：5（サイズ上限：5MB）",
      "月間問合せ数：5000",
      "チャットボット設置ドメイン指定：2箇所",
      "AIモデル：高性能モデル",
      "管理画面アクセス可",
    ],
    buttonVariant: "contained",
  },
  {
    planId: "PREMIUM_PLAN_01",
    priceId: "price_1PuUidP3zz1rfd7rhzowBIWN", //development: "price_1Ps1ZIP3zz1rfd7rMb4s5Da1",
    title: "Premium Plan",
    subheader: "",
    maxNoPdf: 10,
    maxFileSizeMB: 10, // Unit MB
    maxMessagePerMonth: 6000,
    price: "49,800", // Price in japanese yen
    durationInDays: 30,
    maxAllowedDomain: 4, // each chatbot
    description: [
      " チャットボット数：2",
      "PDFアップロード数：10（サイズ上限：10MB）",
      "月間問合せ数：2 × 3000",
      "チャットボット設置ドメイン指定：2 × 2箇所",
      "AIモデル：高性能モデル",
      "管理画面アクセス可",
    ],
    buttonVariant: "contained",
  },
];
