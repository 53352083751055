import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../../../features/chatbot/chatbotSlice";
import SimpleBackdrop from "../../../loader/simple-backdrop";

export default function CreateChatbotModal({ open, handleChangeModal }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const [initialText, setInitialText] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    handleChangeModal(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const updated_initialText =
        initialText || "Hello! How can I assist you today?";
      const updated_description =
        description ||
        "You are an AI assistant with access to the following context from a PDF document. Your task is to answer the user's question based on the information contained within this context.";

      // Ensure all fields are filled
      if (!name) {
        setLoading(false);
        return enqueueSnackbar("チャットボット名称を入力してください。", {
          variant: "warning",
        });
      }

      // Validate input length
      if (
        name.length > 200 ||
        description.length > 500 ||
        initialText.length > 200
      ) {
        setLoading(false);
        return enqueueSnackbar("入力内容が長すぎます。", {
          variant: "warning",
        });
      }

      // Post data
      await axiosInstance.post("/user/chatbot/create", {
        name,
        initialText: updated_initialText,
        description: updated_description,
      });
      enqueueSnackbar("チャットボットが正常に作成されました", {
        variant: "success",
      });

      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      handleClose();
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error || "チャットボットの作成に失敗しました。",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="responsive-dialog-title">
          {"新しいチャットボットを作成しますか？"}
        </DialogTitle>
        <DialogContent>
          <Paper
            sx={{ p: { xs: 2, sm: 4, md: 6 }, mb: 2 }}
            variant="outlined"
            square
          >
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                "& .MuiTextField-root": { m: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                required
                id="outlined-required"
                label="名前"
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="名称"
                fullWidth
              />
              <TextField
                id="outlined-required"
                label="初期テキスト"
                value={initialText}
                onChange={(event) => setInitialText(event.target.value)}
                placeholder="こんにちは。何かお手伝いできることはありますか？"
                fullWidth
              />
              <TextField
                id="outlined-multiline-static"
                label="基本設定"
                multiline
                rows={4}
                placeholder="You are an AI assistant with access to the following context..."
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                fullWidth
              />
            </Box>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="text">
            キャンセル
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
          >
            保存
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
