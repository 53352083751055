import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { formatDate } from "../../constants/index";

export default function UserInfo() {
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const [userDetails, setUserDetails] = React.useState(null);

  React.useEffect(() => {
    if (subscriptionDetails?.subscriptionDetail?.subscription) {
      const { user } = subscriptionDetails?.subscriptionDetail?.subscription;
      setUserDetails(user);
    }
  }, [subscriptionDetails]);

  if (!userDetails) return <h6>Loading..........</h6>;

  return (
    <Box
      component="form"
      sx={{
        p: 2,
        borderRadius: "12PX",
        border: "1px dashed #b4bac5",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
      }}
    >
      <div>
        <Avatar
          sx={{
            mb: 2,
            width: "80px",
            height: "80px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          alt={userDetails?.name || "U"}
          src={userDetails?.picture}
        />
      </div>
      <div>
        <TextField
          disabled
          required
          id="outlined-required"
          label="名前"
          value={userDetails.name}
        />
        <TextField
          disabled
          id="outlined-disabled"
          label="Gメール"
          value={userDetails?.email}
        />
      </div>

      <div>
        <TextField
          disabled
          id="outlined-number"
          label="財布"
          type="number"
          value={userDetails?.mainWallet}
        />

        <TextField
          disabled
          id="outlined-number"
          label="アカウント作成日"
          InputLabelProps={{
            shrink: true,
          }}
          value={formatDate(userDetails?.createdAt)}
        />
      </div>
    </Box>
  );
}
