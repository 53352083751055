import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { format } from "date-fns"; // Import date-fns for formatting
import FileOpenIcon from "@mui/icons-material/FileOpen";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../../../features/chatbot/chatbotSlice";
import ConfirmDialog from "../../../dialog/ConfirmDialog";

export default function AllFileList({ chatbotId }) {
  const dispatch = useDispatch();
  const { chatbot } = useSelector((state) => state.chatbot);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [pdfDocuments, setPdfDocuments] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState("");

  const handleOpenFile = async ({ fileUrl }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/user/chatbot/download-file", {
        fileUrl,
      });
      window.open(response.data, "_blank"); // Open the file URL in a new tab
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error ||
          error.response?.message || { variant: "error" }
      );
    }
    setLoading(false);
  };

  const handleDeleteClick = ({ fileId }) => {
    setSelectedFileId(fileId);
    setConfirmDialogOpen(true);
  };

  const handleDeleteFile = async (fileId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete("/user/chatbot/delete-file", {
        data: { fileId, chatbotId }, // Send data in the body
      });
  
      enqueueSnackbar("ファイルが正常に削除されました。", {
        variant: "success",
      });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedFileId("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setPdfDocuments(chatbotData.pdfDocuments);
      }
    }
  }, [chatbot, chatbotId]);

  const fileListUI = ({ index, fileId, fileName, uplodedDate, fileUrl }) => (
    <ListItem
      key={index}
      sx={{ border: "1px dashed #b4bac5", borderRadius: "12px", mb: 1 }}
      alignItems="flex-start"
    >
      <ListItemAvatar>
        <Avatar alt="File" sx={{ bgcolor: "#1976d2" }}>
          <FileCopyIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="body2" sx={{ wordWrap: "break-word" }}>
            {fileName || "File"}
          </Typography>
        }
        secondary={
          <Stack>
            <Typography
              sx={{ display: "inline", fontSize: "10px" }}
              variant="caption"
              display="block"
              gutterBottom
            >
              アップロード:{" "}
              {format(new Date(uplodedDate), "dd MMM yyyy, hh:mm a")}
            </Typography>
            <Stack direction="row" spacing={2}>
              <Tooltip title="Delete this item">
                <IconButton onClick={() => handleDeleteClick({ fileId })}>
                  <DeleteIcon sx={{ color: "red", fontSize: "1rem" }} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Download this item">
                <IconButton onClick={() => handleOpenFile({ fileUrl })}>
                  <FileOpenIcon sx={{ color: "#1976d2", fontSize: "1rem" }} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        }
      />
    </ListItem>
  );

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={() => handleDeleteFile(selectedFileId)}
          title="チャットボットの削除"
          content="このファイルを削除してもよろしいですか? この操作は元に戻せません。"
        />
      )}
      <Box
        sx={{
          bgcolor: "background.paper",
          maxHeight: "60vh",
          overflowY: "auto", // Allow scrolling if content overflows
          padding: 1,
        }}
      >
        <List>
          {pdfDocuments?.map((item, index) =>
            fileListUI({
              index,
              fileName: item.fileName,
              uplodedDate: item.createdAt,
              fileUrl: item.srcUrl,
              fileId: item._id,
            })
          )}
        </List>
      </Box>
    </React.Fragment>
  );
}
