import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Fab from "@mui/material/Fab";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ChatbotFileUploadModal from "./chat-bot-file-upload";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../../../features/chatbot/chatbotSlice";
import ConfirmDialog from "../../../dialog/ConfirmDialog";
import DashboardButtons from "./ChatbotSubDetailsTab";

export default function ChatbotFileList({ chatbotId }) {
  const dispatch = useDispatch();
  const { chatbot } = useSelector((state) => state.chatbot);
  const { enqueueSnackbar } = useSnackbar();
  const [openFileUploadModal, setOpenFileUploadModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pdfDocuments, setPdfDocuments] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState("");

  const handleOpenFile = async ({ fileUrl }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/user/chatbot/download-file", {
        fileUrl,
      });
      window.open(response.data, "_blank"); // Open the file URL in a new tab
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error ||
          error.response?.message || { variant: "error" }
      );
    }
    setLoading(false);
  };

  const handleDeleteClick = ({ fileId }) => {
    setSelectedFileId(fileId);
    setConfirmDialogOpen(true);
  };

  const handleDeleteFile = async (fileId) => {
    setLoading(true);
    try {
      const response = await axiosInstance.delete("/user/chatbot/delete-file", {
        data: { fileId, chatbotId }, // Send data in the body
      });
      enqueueSnackbar("ファイルが正常に削除されました。", { variant: "success" });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedFileId("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data, { variant: "error" });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setPdfDocuments(chatbotData.pdfDocuments);
      }
    }
  }, [chatbot, chatbotId]);

  return (
    <React.Fragment>
      {loading && <SimpleBackdrop />}
      {openFileUploadModal && (
        <ChatbotFileUploadModal
          chatbotId={chatbotId}
          open={openFileUploadModal}
          handleChangeModal={(state) => setOpenFileUploadModal(state)}
        />
      )}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={() => handleDeleteFile(selectedFileId)}
          title="チャットボットの削除"
          content="このファイルを削除してもよろしいですか? この操作は元に戻せません。"
        />
      )}
      <Stack
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={2}
        sx={{
          minHeight: { md: "88vh", xs: "auto" },
        }}
      >
        {/* top area */}
        <Box>
          <DashboardButtons chatbotId={chatbotId} />
        </Box>
        {/* Bottom area */}
        <Stack sx={{ mt: 2 }}>
          <Box sx={{}}>
            <Typography
              textAlign={"center"}
              variant="overline"
              display="block"
              gutterBottom
            >
              さらにファイルをアップロードしますか?
            </Typography>
            <Fab
              onClick={() => setOpenFileUploadModal(true)}
              sx={{
                background: "#000000c9",
                color: "white",
                textTransform: "none", // prevents text from being uppercased
                "&:hover": {
                  background: "#000000c9", // ensure background color doesn't change on hover
                  color: "white", // ensure text color doesn't change on hover
                },
              }}
              variant="extended"
              color="primary"
            >
              <CloudUploadIcon sx={{ mr: 1 }} />
              今すぐアップロード
            </Fab>
          </Box>
        </Stack>
      </Stack>
    </React.Fragment>
  );
}
