import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";

export default function EmptyChatList({ open, handleOpenBotCreateModal }) {
  const sideBarCreateBotUi = (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        textAlign={"center"}
        variant="overline"
        display="block"
        gutterBottom
      >
        新しいチャットボットを作成しますか？
      </Typography>
      <Fab
        onClick={() => handleOpenBotCreateModal(true)}
        sx={{
          width: "100%",
          background: "#000000c9",
          color: "white",
          textTransform: "none", // prevents text from being uppercased
          "&:hover": {
            background: "#000000c9", // ensure background color doesn't change on hover
            color: "white", // ensure text color doesn't change on hover
          },
        }}
        variant="extended"
        size="small"
        color="primary"
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        {open && "チャットボット作成"}
      </Fab>
    </Box>
  );

  return (
    <Card
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 275,
        maxWidth: 375,
        width: "90%", // for responsiveness on smaller screens
        border: "1px dashed #b4bac5",
        borderRadius: "12px",
        boxShadow: "none", // Removes the card shadow
        p: { md: 8, xs: 2 },
      }}
    >
      <CardContent>
        <Typography textAlign={"center"} variant="h5" component="div">
          初めてのチャットボット作成
        </Typography>
      </CardContent>
      <CardActions>{sideBarCreateBotUi}</CardActions>
    </Card>
  );
}
