import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import animatedBotSrc from "../../../../images/animated-bot/bot-01.gif";

const chatbotGuidelines = [
  {
    step: 1,
    title: "サブスクリプション申込",
    description: "サブスクリプションを申し込むには？",
    srcUrl: "https://ncchatbot.com/guide-details/subs-app",
  },
  {
    step: 2,
    title: "チャットボット作成",
    description: "チャットボットを作成するには？",
    srcUrl: "https://ncchatbot.com/guide-details/chatbot-create",
  },
  {
    step: 3,
    title: "チャットボット削除",
    description: "チャットボットを削除するには？",
    srcUrl: "https://ncchatbot.com/guide-details/chatbot-delete",
  },
  {
    step: 4,
    title: "PDFアップロード",
    description: "PDFファイルをアップロードするには？",
    srcUrl: "https://ncchatbot.com/guide-details/upload-files",
  },
  {
    step: 5,
    title: "チャットボットの設定",
    description: "チャットボットを設定するには？",
    srcUrl: "https://ncchatbot.com/guide-details/settings",
  },
  {
    step: 6,
    title: "サブスクリプション解除",
    description: "サブスクリプションをキャンセルするには？",
    srcUrl: "https://ncchatbot.com/guide-details/unsubscribe",
  },
  {
    step: 7,
    title: "チャットボットの無効化",
    description: "チャットボットを無効にするには？",
    srcUrl: "https://ncchatbot.com/guide-details/chatbot-disable",
  },
  {
    step: 8,
    title: "チャットボットの使用",
    description: "チャットボットを使用するには？",
    srcUrl: "https://ncchatbot.com/guide-details/chatbot-usage",
  },
  {
    step: 9,
    title: "AIへの指示",
    description: "AIへの指示文を設定するにはどうすればいいですか？",
    srcUrl: "https://ncchatbot.com/guide-details/instructions",
  },
];

export default function Testimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 4 },
        pb: { xs: 8, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          mt: 8,
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        {/* <Typography component="h2" variant="h4" color="text.primary">
          Guideline
        </Typography> */}
        <img
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "200px",
          }}
          src={animatedBotSrc}
          alt="ncc smart ai"
        />
        <Typography variant="body1" color="text.secondary">
          以下は、当社のチャットボットのガイドラインです。NCC Smart AI
          の最もインタラクティブな機能を活用して、ビジネスを成長させましょう。
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {chatbotGuidelines.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  pr: 2,
                }}
              >
                <Typography
                  component="span"
                  variant="h5"
                  sx={{
                    ml: 2,
                    textAlign: "center",
                    fontSize: "clamp(1rem, 5vw, 1rem)",
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "primary.main"
                        : "primary.light",
                  }}
                >
                  {item.title}
                </Typography>

                <Button
                  onClick={() => window.open(`${item.srcUrl}`)}
                  variant="contained"
                  color="primary"
                >
                  詳細
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
