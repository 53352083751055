import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useTheme } from "@mui/material/styles";
import DropZoneFileUpload from "../../../upload/drop-zone-file-upload";
import axiosInstance from "../../../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import {
  fetchChatbotAndFileLimitDetails,
  fetchChatbotList,
} from "../../../../features/chatbot/chatbotSlice";

export default function ChatbotFileUploadModal({
  chatbotId,
  open,
  handleChangeModal,
}) {
  const dispatch = useDispatch();
  const [selectedfile, setSelectedFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleFileSelect = (file) => {
    setSelectedFile(file);
  };

  const handleClose = () => {
    handleChangeModal(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Initialize form data
      const formData = new FormData();
      formData.append("file", selectedfile); // Append the selected file
      formData.append("chatbotId", chatbotId); // Append the chatbotId

      // Ensure all fields are filled
      if (!selectedfile) {
        // Show notification for missing fields (use your notification method)
        return enqueueSnackbar("ファイルをアップロードしてください。", {
          variant: "warning",
        });
      }

      // Post the form data to the server
      await axiosInstance.post("/user/chatbot/addpdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Handle success response
      enqueueSnackbar("ファイルのアップロードが正常に完了しました。", {
        variant: "success",
      });

      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());

      handleClose();

      // Show success notification (use your notification method)
    } catch (error) {
      // Handle error response
      console.error("ファイルのアップロードに失敗しました。", error);
      enqueueSnackbar(
        error?.response?.data?.error ||
          "ファイルのアップロードに失敗しました。",
        { variant: "error" }
      );
      // Show error notification (use your notification method)
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"ファイルをアップロードしてください。"}
        </DialogTitle>
        <DialogContent>
          <DropZoneFileUpload
            file={selectedfile}
            handleFileSelect={handleFileSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            キャンセル
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            保存
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
