import React from "react";
import LandingPage from "./landing-page/landing-page";

export default function EmptyChatbot() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}
