import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import nccAiLogo from "../images/logo_color_tate-v2.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axiosInstance from "../api/axiosInstance";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useAuth from "../auth/useAuth";
import "./styles.css";

const googleClientId =
  "254824390632-0vvta3jo5hst7sa7evi7khfeht6jdf80.apps.googleusercontent.com";
// "254824390632-0vvta3jo5hst7sa7evi7khfeht6jdf80.apps.googleusercontent.com"; // ncc account gauth
// "678734525044-b0v467a65bfg8t9oi16lds1gqtjoh1rq.apps.googleusercontent.com"; // test oauth

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"さらに詳しく © "}
      <Link color="inherit" href="https://ncchatbot.com/">
        NCCチャットボット
      </Link>{" "}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const { login, logout, isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleServerLogin = async (tokenInfo, accessToken) => {
    const { email, name, picture } = tokenInfo;
    try {
      const body = { accessToken };

      const response = await axiosInstance.post(
        `/user/auth/google-login`,
        body
      );
      const { token, role } = response.data;

      const isLogin = login({ email, name, picture, role, token });
      if (isLogin) navigate("/chat");
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Failed to login", {
        variant: "error",
      });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="sigin-in-background-overlay">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 28,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "white",
              p: { xs: 2, md: 4 },
              border: "2px solid black",
            }}
          >
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img
              style={{ maxWidth: "200px", marginBottom: "10px" }}
              src={nccAiLogo}
              alt="ncc ai chatbot"
            />
            <Typography
              sx={{ mt: 4, fontWeight: "700" }}
              textAlign={"center"}
              component="h1"
              variant="h5"
            >
              Sign In
            </Typography>
            <Box noValidate sx={{ p: { md: 8, xs: 4 } }}>
              <GoogleOAuthProvider clientId={`${googleClientId}`}>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    const { credential } = credentialResponse;
                    const accessToken = credential;
                    // Decode the token to extract user info
                    const base64Url = accessToken.split(".")[1];
                    const base64 = base64Url
                      .replace(/-/g, "+")
                      .replace(/_/g, "/");
                    const jsonPayload = decodeURIComponent(
                      atob(base64)
                        .split("")
                        .map(
                          (c) =>
                            `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
                        )
                        .join("")
                    );
                    const tokenInfo = JSON.parse(jsonPayload);
                    // console.log('Decoded Token:', tokenInfo);
                    handleServerLogin(tokenInfo, accessToken);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  useOneTap
                />
              </GoogleOAuthProvider>
            </Box>
            {/* <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="ユーザー名"
                name="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="パスワード"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="グイン状態を保持する"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                デモにサインイン
              </Button>
            </Box>  */}
            <Copyright sx={{ mt: 8, mb: 4 }} />
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
