import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import nccSmartAILogo from "../../images/logo_color_tate.png";
import useAuth from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import axiosInstance from "../../api/axiosInstance";
import ShowLimitsView from "../chat/chatbox/sub/show-chatbot-limit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Fab from "@mui/material/Fab";

const logoStyle = {
  width: "50px",
  height: "auto",
  cursor: "pointer",
};

function Sidebar({ open, toggleDrawer, handleOpenBotCreateModal }) {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    if (logout()) navigate("/signin");
  };

  const handleManageSubscription = async () => {
    try {
      const response = await axiosInstance.get(
        "/payment/create-customer-portal-session"
      );
      toggleDrawer();
      window.location.href = `${response.data.url}`;
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || "Something went wrong", {
        variant: "error",
      });
    }
  };

  const sideBarCreateBotUi = (
    <Box
      sx={{
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography
        textAlign={"center"}
        variant="overline"
        display="block"
        gutterBottom
      >
        新しいチャットボットを作成しますか？
      </Typography>
      <Fab
        onClick={() => handleOpenBotCreateModal(true)}
        sx={{
          width: "100%",
          background: "#000000c9",
          color: "white",
          textTransform: "none", // prevents text from being uppercased
          "&:hover": {
            background: "#000000c9", // ensure background color doesn't change on hover
            color: "white", // ensure text color doesn't change on hover
          },
        }}
        variant="extended"
        size="small"
        color="primary"
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        {open && "チャットボット作成"}
      </Fab>
    </Box>
  );

  // Define the content of the drawer
  const drawerContent = (
    <Box
      sx={{
        width: 250, // Adjust width as needed
        p: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      {/* Top Section: Logo and Navigation Items */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            cursor: "pointer",
          }}
          onClick={() => navigate("/chat")}
        >
          <img src={nccSmartAILogo} style={logoStyle} alt="NCC AI Logo" />
          <Typography variant="h6" sx={{ ml: 1 }}>
            NCC Chatbot
          </Typography>
        </Box>
        <Box sx={{ mb: 2, mt: 2 }}>{sideBarCreateBotUi}</Box>
        <Divider sx={{ mt: 4 }} />
        <MenuItem onClick={handleManageSubscription}>登録管理</MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/price")}>登録する</MenuItem>
        <Divider />
      </Box>

      {/* Bottom Section: Theme Toggle and Logout */}
      <Box>
        <Box sx={{ mb: 2 }}>
          <ShowLimitsView />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Button
            sx={{ background: "black", color: "whitesmoke" }}
            variant="contained"
            size="small"
            onClick={handleLogout}
          >
            ログアウト
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left" // Change to "left" if you prefer the drawer on the left
      open={open}
      onClose={() => toggleDrawer(false)}
    >
      {drawerContent}
    </Drawer>
  );
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleOpenBotCreateModal: PropTypes.func.isRequired,
};

export default Sidebar;
