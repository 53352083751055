import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Divider, Typography } from "@mui/material";
import UserInfo from "./user-info";
import UserSubscription from "./user-subscription";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function UserProfileDialog({ open, handleClose }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Button autoFocus color="inherit" onClick={handleClose}>
              閉じる
            </Button>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            p: {
              xs: 2,
              md: 4,
              marginLeft: "auto !important",
              marginRight: "auto !important",
            },
            bgcolor: "#f5f5f5",
          }}
          maxWidth="xl"
        >
          <Typography
            sx={{ mb: 1 }}
            variant="h4"
            fontWeight={650}
            textAlign={"center"}
          >
            プロフィール
          </Typography>
          <Divider sx={{ width: "100%" }} />
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon position tabs example"
          >
            <Tab
              icon={<AccountBoxIcon />}
              iconPosition="start"
              label="プロフィール"
            />
            <Tab icon={<LoyaltyIcon />} iconPosition="start" label="定期購読" />
            {/* <Tab icon={<PaymentsIcon />} iconPosition="start" label="Payment" /> */}
          </Tabs>
          {value === 0 && (
            <CustomTabPanel value={value} index={0}>
              <UserInfo />
            </CustomTabPanel>
          )}

          {value === 1 && (
            <CustomTabPanel value={value} index={1}>
              <UserSubscription />
            </CustomTabPanel>
          )}

          {/* {value === 2 && (
            <CustomTabPanel value={value} index={2}>
              Paymnent details
            </CustomTabPanel>
          )} */}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
