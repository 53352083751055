import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Pricing from "./Pricing";
import aiBotImg from "../../images/logo_color_tate.png";
export default function SubscriptionModal({ open, handleClose }) {
  return (
    <React.Fragment>
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent sx={{ mt: -4 }}>
          <img
            src={aiBotImg}
            style={{ maxWidth: "100px", marginBottom: "-150px" }}
            alt="smart ai"
          />
          <Pricing />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus variant="contained">
          閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
