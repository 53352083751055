import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate } from "../../constants/index";

export default function UserSubscription() {
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const [subscription, setSubscription] = React.useState(null);

  React.useEffect(() => {
    if (subscriptionDetails?.subscriptionDetail?.subscription) {
      const { subscription } =
        subscriptionDetails?.subscriptionDetail?.subscription?.user;
      setSubscription(subscription);
    }
  }, [subscriptionDetails]);

  if (!subscription) return <h6>Loading..........</h6>;

  return (
    <Box
      component="form"
      sx={{
        p: 2,
        borderRadius: "12PX",
        border: "1px dashed #b4bac5",
        "& .MuiTextField-root": { m: 1, width: "35ch" },
      }}
    >
      <div>
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            textAlign: "center",
          }}
        >
          {subscription?.planId}
        </Typography>
      </div>
      <div>
        <TextField
          disabled
          required
          id="outlined-required"
          label="当月合計アップロード数"
          value={subscription?.montlyPdfUpload}
        />
        <TextField
          disabled
          id="outlined-disabled"
          label="当月合計ダウンロード数"
          value={subscription?.montlyPdfDownload}
        />
      </div>
      <div>
        <TextField
          disabled
          id="outlined-number"
          label="ステータス"
          InputLabelProps={{
            shrink: true,
          }}
          value={subscription?.status}
        />

        <TextField
          disabled
          id="outlined-number"
          label="次回支払日付"
          InputLabelProps={{
            shrink: true,
          }}
          value={formatDate(subscription?.expireDate)}
        />
      </div>
    </Box>
  );
}
