import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import axiosInstance from "../../../../api/axiosInstance";
import Stack from "@mui/material/Stack";
import styled from "@emotion/styled";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

const ScrollWrapper = styled(Box)`
  max-height: 550px;
  overflow-y: scroll; /* Vertical scrollbar */
  overflow-x: auto; /* Horizontal scrollbar */

  /* Vertical scrollbar styles */
  ::-webkit-scrollbar {
    width: 2px; /* Vertical scrollbar width */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 12px; /* Rounded corners */
  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 12px; /* Rounded corners */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
  }

  /* Horizontal scrollbar styles */
  ::-webkit-scrollbar {
    height: 6px; /* Horizontal scrollbar height */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
    border-radius: 10px; /* Rounded corners */
  }

  ::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Thumb color on hover */
  }
`;

export default function ChatbotQuestions({ chatbotId }) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/user/chatbot/get-questions/${chatbotId}`,
          {
            params: {
              page: page,
              limit: 10, // Adjust based on how many items you want per page
            },
          }
        );
        setQuestions(response.data.questions);
        setTotalPages(Math.ceil(response.data.total / 10)); // Adjust the divisor based on your limit per page
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [chatbotId, page]);

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed #b4bac5",
        borderRadius: "12px",
        p: 2,
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : questions.length === 0 ? (
        <Typography sx={{ textAlign: "center", color: "#555", p: 2 }}>
          No questions available.
        </Typography>
      ) : (
        <>
          <ScrollWrapper>
            <List dense>
              {questions.map((question) => (
                <ListItem key={question._id}>
                  <ListItemText
                    primary={
                      <Stack alignItems={"center"} direction={"row"}>
                        <DynamicFormIcon fontSize="18px" /> &nbsp;
                        <p>{question.question}</p>
                      </Stack>
                    }
                    secondary={
                      <Stack
                        spacing={1}
                        alignItems={"center"}
                        direction={"row"}
                        sx={{ mt: -1 }}
                      >
                        <WatchLaterIcon
                          sx={{ color: "#00000047" }}
                          fontSize="14px"
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "12px", color: "#000000a3" }}
                        >
                          {new Date(question.createdAt).toLocaleDateString()}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </ScrollWrapper>
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChange}
              size="small"
              variant="outlined"
              shape="rounded"
              siblingCount={0}
              sx={{
                justifyContent: "center",
                display: "flex",
                mt: 1,
                "& .MuiPaginationItem-root": {
                  mx: 0.7, // Horizontal margin for pagination buttons
                },
              }}
            />
          )}
        </>
      )}
    </Box>
  );
}
