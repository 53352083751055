import axios from "axios";
import { baseURL } from "../constants/index";
// import { useGoogleAuth } from 'src/auth/googleAuth';

const axiosInstance = axios.create({
  baseURL: `${baseURL}`,
  headers: {
    "Content-Type": "application/json",
    // You can add other common headers here
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem("SMARTAI_USER_TOKEN") ||
      localStorage.getItem("googleAuthToken") ||
      localStorage.getItem("authToken"); // Use the custom hook to get the token
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to the login page
      window.location.href = "/signin";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
