import React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import CustomListItem from "./chatbot-list-item";

export default function ChatbotListItems({
  open,
  chatbots,
  selectdChatbot,
  setSelectedChatbot,
}) {
  return (
    <List
      sx={{
        height: "100vh",
        maxHeight: "60vh",
        overflowY: chatbots?.length > 5 ? "auto" : "hidden",
      }}
    >
      {chatbots?.map((chatBot, index) => (
        <div key={chatBot._id}>
          <CustomListItem
            open={open}
            chatBot={chatBot}
            selectdChatbot={selectdChatbot}
            setSelectedChatbot={setSelectedChatbot}
          />
          {index !== chatbots.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  );
}
