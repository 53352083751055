export const baseURL = "https://api.ncchatbot.com"; // "http://localhost:3000";
export const publicBotUI = "https://public-chat.ncchatbot.com";

export const faqDataEng = [
  {
    question: "Are the payments for the chatbot services refundable?",
    answer:
      "No, all payments made for our chatbot services are non-refundable.",
  },
  {
    question:
      "What are the terms and conditions for using your chatbot services?",
    answer:
      "By using our chatbot services, you agree to abide by our terms and conditions, including compliance with applicable laws, not using the service for illegal activities, and not distributing harmful content. For full details, please review our terms and conditions on our website.",
  },
  {
    question: "How do you handle data privacy for the chatbot services?",
    answer:
      "We take data privacy very seriously. All user data is encrypted and stored securely. We do not share your personal information with third parties without your consent, except as required by law. For more information, please refer to our privacy policy.",
  },
  {
    question: "How can I manage my account details?",
    answer:
      "You can manage your account details by google login on our website. From there, you can update your personal information, manage your subscription, and view your usage history.",
  },
  {
    question: "What subscription plans do you offer for the chatbot services?",
    answer:
      "We offer three subscription plans: Free, Standard, and Premium. The Free plan is available for 15 days without requiring a credit card. The Standard and Premium plans offer additional features and are billed monthly. You can switch between plans at any time.",
  },
  {
    question: "How can I get support for issues with the chatbot services?",
    answer:
      "If you encounter any issues or have questions about our chatbot services, you can contact our support team via google form or through the phone call. Our team is available 24/7 to assist you.",
  },
  {
    question: "What features are included in your chatbot services?",
    answer:
      "Our chatbot services include features such as natural language processing, pdf file upload, file search, question and answer capabilities, and integration with various systems. Depending on your subscription plan, additional features may be available.",
  },
  {
    question: "What security measures are in place for the chatbot services?",
    answer:
      "We implement robust security measures to protect your data, including encryption, secure access controls, and regular security audits. Your data is protected from unauthorized access and breaches.",
  },
  {
    question: "Can the chatbot services be integrated with other systems?",
    answer:
      "Yes, our chatbot services can be integrated with various systems such as CRM platforms, e-commerce websites, and other business applications. Please contact our sales team for more information on integration options.",
  },
  {
    question: "Is there a trial period available for your chatbot services?",
    answer:
      "Yes, we offer a 15 days free plan for our chatbot services. During this period, you can explore the features and decide if our services meet your needs before committing to a subscription.",
  },
  {
    question: "What happens to my data if I switch my subscription plan?",
    answer:
      "If you switch your subscription plan, all data associated with the previous plan, including files and chatbot configurations, will be deleted. Please make sure to back up any important information before switching plans.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "You can cancel your subscription at any time by logging into your account and navigating to the subscription management section. There are no penalties or fees for canceling, and you will continue to have access to the chatbot services until the end of your billing period.",
  },

  {
    question: "Are there any security measures for user authentication?",
    answer:
      "Yes, we use secure authentication methods including Google Sign-In to ensure that your account is protected. All login sessions are encrypted and monitored for suspicious activities.",
  },
];

export const faqData = [
  {
    question: "チャットボットサービスの支払いは返金可能ですか？",
    answer: "いいえ、チャットボットサービスの支払いはすべて返金不可です。",
  },
  {
    question: "チャットボットサービスの利用規約は何ですか？",
    answer:
      "チャットボットサービスを利用することで、適用される法律を遵守し、違法行為にサービスを使用しない、有害なコンテンツを配布しないなどの利用規約に同意したことになります。詳細は、当社のウェブサイトの利用規約をご確認ください。",
  },
  {
    question:
      "チャットボットサービスのデータプライバシーはどのように処理されますか？",
    answer:
      "データプライバシーを非常に重視しています。すべてのユーザーデータは暗号化され、安全に保存されます。法律で義務付けられている場合を除き、ユーザーの同意なしに個人情報を第三者と共有することはありません。詳細は、プライバシーポリシーをご参照ください。",
  },
  {
    question: "アカウント情報はどのように管理できますか？",
    answer:
      "当社のウェブサイトでGoogleログインを使用してアカウント情報を管理できます。そこから、個人情報の更新、サブスクリプションの管理、使用履歴の確認ができます。",
  },
  {
    question:
      "チャットボットサービスのサブスクリプションプランにはどのようなものがありますか？",
    answer:
      "チャットボットサービスには、無料、スタンダード、プレミアムの3つのサブスクリプションプランがあります。無料プランは15日間利用可能で、クレジットカードは必要ありません。スタンダードおよびプレミアムプランでは追加機能が提供され、月額で請求されます。プランはいつでも切り替え可能です。",
  },
  {
    question:
      "チャットボットサービスの問題についてサポートを受けるにはどうすればいいですか？",
    answer:
      "チャットボットサービスに関する問題や質問がある場合は、Googleフォームを通じて、または電話でサポートチームに連絡できます。当社のチームは24時間365日対応しています。",
  },
  {
    question: "チャットボットサービスにはどのような機能が含まれていますか？",
    answer:
      "チャットボットサービスには、自然言語処理、PDFファイルのアップロード、ファイル検索、質問応答機能、さまざまなシステムとの統合などの機能が含まれています。サブスクリプションプランに応じて、追加機能が利用可能です。",
  },
  {
    question:
      "チャットボットサービスのセキュリティ対策はどのようになっていますか？",
    answer:
      "当社では、データを保護するために、暗号化、セキュアなアクセス制御、定期的なセキュリティ監査などの強力なセキュリティ対策を実施しています。データは不正アクセスや漏洩から保護されています。",
  },
  {
    question: "チャットボットサービスは他のシステムと統合できますか？",
    answer:
      "はい、チャットボットサービスはCRMプラットフォーム、eコマースサイト、その他のビジネスアプリケーションなど、さまざまなシステムと統合できます。統合オプションの詳細については、営業チームにお問い合わせください。",
  },
  {
    question: "チャットボットサービスには試用期間がありますか？",
    answer:
      "はい、チャットボットサービスには15日間の無料プランがあります。この期間中、機能をお試しいただき、サービスがニーズに合っているかどうかをご確認いただけます。",
  },
  {
    question: "サブスクリプションプランを変更すると、データはどうなりますか？",
    answer:
      "サブスクリプションプランを変更すると、以前のプランに関連付けられていたすべてのデータ（ファイルやチャットボットの設定を含む）は削除されます。プランを変更する前に重要な情報のバックアップを必ず取ってください。",
  },
  {
    question: "サブスクリプションをキャンセルするにはどうすればよいですか？",
    answer:
      "アカウントにログインし、サブスクリプション管理セクションに進むことで、いつでもサブスクリプションをキャンセルできます。キャンセルにはペナルティや手数料はかかりません。また、課金期間が終了するまで、チャットボットサービスを引き続き利用できます。",
  },
  {
    question: "ユーザー認証のためのセキュリティ対策はありますか？",
    answer:
      "はい、Googleサインインを含むセキュアな認証方法を使用して、アカウントを保護しています。すべてのログインセッションは暗号化され、不審な活動が監視されています。",
  },
];

export const formatDate = (date) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
  }).format(new Date(date));
};
