export function underScoreTitle(text) {
  if (!text) return "";

  // Split the string by underscores
  const parts = text.split("_");

  // Transform each part to title case
  const titleCasedParts = parts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });

  // Join the parts back into a single string with spaces
  return titleCasedParts.join(" ");
}

export function formatTitle(input) {
  if (!input) return "";

  // Split the input string by underscores or spaces
  const words = input.split(/_| /);

  // Capitalize the first letter of each word and join them with spaces
  const formattedTitle = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return formattedTitle;
}
