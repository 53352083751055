import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";

function ShowLimitsViewSkeleton() {
  return (
    <Box sx={{ mt: 1, p: 1, border: "1px solid #ddd", borderRadius: "8px" }}>
      <Typography fontWeight={650} variant="subtitle1" sx={{ mb: 1 }}>
      使用量 / 上限
      </Typography>
      <Box sx={{ mb: 1 }}>
        <Typography variant="subtitle2">
          <Skeleton width={100} />
        </Typography>
        <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 5 }} />
      </Box>
      <Box>
        <Typography variant="subtitle2">
          <Skeleton width={100} />
        </Typography>
        <Skeleton variant="rectangular" height={10} sx={{ borderRadius: 5 }} />
      </Box>
    </Box>
  );
}

const CustomLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2)",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "black",
    borderRadius: 5,
  },
});

export default function ShowLimitsView() {
  const { limitDetails } = useSelector((state) => state.chatbot);

  const { loading } = limitDetails;

  if (loading) return <ShowLimitsViewSkeleton />;

  return (
    <Box sx={{ mt: 1, p: 1, border: "1px solid #ddd", borderRadius: "8px" }}>
      <Typography fontWeight={650} variant="subtitle1" sx={{ mb: 1 }}>
      使用量 / 上限
      </Typography>
      <Box sx={{ mb: 1 }}>
        <Typography variant="subtitle2">
          Chatbots: {limitDetails.noOfChatbot} / {limitDetails.maxNoOfChatbot}
        </Typography>
        <CustomLinearProgress
          variant="determinate"
          value={(limitDetails.noOfChatbot / limitDetails.maxNoOfChatbot) * 100}
        />
      </Box>
      <Box>
        <Typography variant="subtitle2">
          Files: {limitDetails.noOfFiles} / {limitDetails.maxNoOfFiles}
        </Typography>
        <CustomLinearProgress
          variant="determinate"
          value={(limitDetails.noOfFiles / limitDetails.maxNoOfFiles) * 100}
        />
      </Box>
      <Typography
        sx={{
          fontSize: "10px",
          mt: 1,
          color:
            limitDetails.montlyPdfUpload === limitDetails.maxNoFileUploadMontly
              ? "red"
              : "black",
        }}
        variant="body2"
      >
        月間アップロードファイル数: {limitDetails.montlyPdfUpload} /{" "}
        {limitDetails.maxNoFileUploadMontly}
      </Typography>
    </Box>
  );
}
