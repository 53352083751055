import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import BookIcon from "@mui/icons-material/Book";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MoreIcon from "@mui/icons-material/MoreVert";
import chatBotLogo from "../../images/logo_color_tate.png";
import CreateChatbotModal from "../chat/chatbox/sub/create-chatbot-modal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../features/chatbot/chatbotSlice";

import ChatbotListItems from "./ChatbotListItems";
import { Divider, Stack } from "@mui/material";
import ChatBoxV2 from "../chat/chatbox/ChatBoxV2";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import EmptyChatList from "../chat/EmptyChatList";

export default function MobileChatDashboard() {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const { chatbot, subscriptionDetails } = useSelector(
    (state) => state.chatbot
  );
  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [openChatbotModal, setOpenChatbotModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [selectdChatbot, setSelectedChatbot] = React.useState(null); // React.useState(chatbots[0]);
  const [chatbots, setChatbots] = React.useState([]);
  const [subscription, setSubscription] = React.useState(null);

  React.useEffect(() => {
    if (subscriptionDetails.subscriptionDetail) {
      const { expireDate, status } =
        subscriptionDetails.subscriptionDetail.subscription.user.subscription;
      const { title } =
        subscriptionDetails.subscriptionDetail.subscription.plan;

      setSubscription({ expireDate, status, title });
    }
  }, [subscriptionDetails]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    if (logout()) navigate("/signin");
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => navigate("/price")}>
        <LocalOfferIcon /> &nbsp; <p>価格</p>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => navigate("/guide")}>
        <BookIcon /> &nbsp; <p>ガイドライン</p>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => handleLogout()}>
        <ExitToAppIcon /> &nbsp; <p>ログアウト</p>
      </MenuItem>
    </Menu>
  );

  // Effect to handle chatbot list and automatic selection
  React.useEffect(() => {
    if (chatbot.chatbots) {
      setChatbots(chatbot.chatbots);

      // Automatically select the first chatbot if none is selected
      if (chatbot.chatbots.length > 0 && !selectdChatbot) {
        setSelectedChatbot(chatbot.chatbots[0]);
      }
      if (chatbot.chatbots.length === 0) {
        setSelectedChatbot(null);
      }
      // Removed the else block to prevent infinite re-rendering
    }
  }, [chatbot, selectdChatbot]);

  // Fetch chatbots and subscription details on component mount
  React.useEffect(() => {
    dispatch(fetchChatbotList());
    dispatch(fetchChatbotAndFileLimitDetails());
  }, [dispatch]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {openSidebar && (
        <Sidebar
          open={openSidebar}
          toggleDrawer={(state) => setOpenSidebar(state)}
          handleOpenBotCreateModal={(state) => setOpenChatbotModal(state)}
        />
      )}
      {openChatbotModal && (
        <CreateChatbotModal
          open={openChatbotModal}
          handleChangeModal={(state) => setOpenChatbotModal(state)}
        />
      )}
      <AppBar position="static">
        <Toolbar sx={{ backgroundColor: "black" }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpenSidebar(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { sm: "block" } }}>
            <img
              src={chatBotLogo}
              style={{ width: "40px", height: "40px" }}
              alt="ncc ai"
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}

      {/* Show Chatbox and file list */}
      <Stack sx={{ p: 1 }} spacing={2}>
        <Box>
          {chatbot.chatbots.length > 0 && (
            <ChatbotListItems
              open={open}
              chatbots={chatbots}
              selectdChatbot={selectdChatbot}
              setSelectedChatbot={(chatbot) => setSelectedChatbot(chatbot)}
            />
          )}

          {chatbot?.chatbots?.length === 0 && (
            <EmptyChatList
              open={openChatbotModal}
              handleOpenBotCreateModal={(state) => setOpenChatbotModal(state)}
            />
          )}
        </Box>
        <Box>
          {selectdChatbot && (
            <ChatBoxV2
              chatbot={selectdChatbot}
              initialText="SMART AI が会話に参加しました。"
              type="CHAT"
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
