import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import { MdOutlineCloudUpload } from "react-icons/md";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import Button from "@mui/material/Button";
import axiosInstance from "../../api/axiosInstance";

const acceptedFileTypes = ["image/jpeg", "image/png"];
const maxFileSizeBytes = 10 * 1024 * 1024; // 10MB
const maxImageDimensions = { width: 800, height: 800 };

export default function DropZoneImageUpload({
  chatbotId,
  file,
  existingLogoSrc,
  existingFile,
  handleFileSelect,
  handleDeleteLogo
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [preview, setPreview] = useState(
    file ? URL.createObjectURL(file) : existingFile || null
  );

  useEffect(() => {
    if (existingLogoSrc) setPreview(existingLogoSrc);
  }, [existingLogoSrc]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => {
        const fileTypeAccepted = acceptedFileTypes.includes(file.type);
        const fileSizeAccepted = file.size <= maxFileSizeBytes;
        if (!fileTypeAccepted) {
          enqueueSnackbar(`File type not supported: ${file.name}`, {
            variant: "error",
          });
        } else if (!fileSizeAccepted) {
          enqueueSnackbar(`File size exceeds the limit: ${file.name}`, {
            variant: "error",
          });
        }
        return fileTypeAccepted && fileSizeAccepted;
      });

      if (filteredFiles.length > 0) {
        const image = new Image();
        image.onload = () => {
          if (
            image.width <= maxImageDimensions.width &&
            image.height <= maxImageDimensions.height
          ) {
            handleFileSelect(filteredFiles[0]); // Only accept the first file
            setPreview(URL.createObjectURL(filteredFiles[0]));
          } else {
            enqueueSnackbar(
              `Image dimensions exceed the limit (800x800): ${filteredFiles[0].name}`,
              {
                variant: "error",
              }
            );
          }
        };
        image.src = URL.createObjectURL(filteredFiles[0]);
      }
    },
    [enqueueSnackbar, handleFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(","),
    multiple: false, // Only allow a single file
  });

  const handleDelete = () => {
    axiosInstance
      .delete(`/user/chatbot/delete-logo/${chatbotId}`)
      .then((response) => {
        setPreview(null);
        enqueueSnackbar("Logo deleted successfully.", { variant: "success" });
        handleDeleteLogo();
      })
      .catch((error) => {
        enqueueSnackbar(`Error deleting logo: ${error.message}`, {
          variant: "error",
        });
      });
  };

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          sx={{
            border: "2px dotted #0000003b",
            p: { md: 4, xs: 2 },
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          {preview ? (
            <img
              src={preview}
              alt="Preview"
              style={{ maxWidth: "100%", maxHeight: "400px" }}
            />
          ) : (
            <>
              <MdOutlineCloudUpload size={48} />
              {isDragActive ? (
                <p>ファイルをアップロード ...</p>
              ) : (
                <p>
                  ファイルをここにドラッグ＆ドロップするか、
                  クリックしてファイルを選択してください
                </p>
              )}
            </>
          )}
        </Box>
      </div>
      <Box sx={{ mt: 2 }}>
        {existingFile && (
          <Button
            sx={{ textTransform: "none" }}
            color="error"
            variant="outlined"
            onClick={handleDelete}
          >
            Remove logo
          </Button>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="textSecondary">
          最大ファイルサイズ: 10MB, 最大画像サイズ: 800x800ピクセル
        </Typography>
      </Box>
    </div>
  );
}
