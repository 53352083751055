import { useState } from "react";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const login = async ({ token, email, name, picture, role }) => {
    // Replace this with your actual login logic (e.g., API call)
    localStorage.setItem("SMARTAI_USER_TOKEN", token);
    localStorage.setItem("SMARTAI_USER_EMAIL", email);
    localStorage.setItem("SMARTAI_USER_NAME", name);
    localStorage.setItem("SMARTAI_USER_PICTURE", picture);
    localStorage.setItem("SMARTAI_USER_ROLE", role);

    setIsAuthenticated(true);
    setUser({ token, email, name, picture, role });

    return true;
  };

  const logout = () => {
    localStorage.removeItem("SMARTAI_USER_TOKEN");
    localStorage.removeItem("SMARTAI_USER_EMAIL");
    localStorage.removeItem("SMARTAI_USER_NAME");
    localStorage.removeItem("SMARTAI_USER_PICTURE");
    localStorage.removeItem("SMARTAI_USER_ROLE");
    
    setIsAuthenticated(false);
    setUser({});
    return true;
  };

  return {
    isAuthenticated,
    user,
    login,
    logout,
  };
};

export default useAuth;
