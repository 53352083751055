import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Box from "@mui/material/Box";
import { MdOutlineCloudUpload } from "react-icons/md";
import Typography from "@mui/material/Typography";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";

const acceptedFileTypes = [".pdf"];

export default function DropZoneFileUpload({ file, handleFileSelect }) {
  const { enqueueSnackbar } = useSnackbar();
  const { subscriptionDetails } = useSelector((state) => state.chatbot);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const subscriptionPlan =
        subscriptionDetails?.subscriptionDetail?.subscription?.plan;
      if (!subscriptionPlan) {
        enqueueSnackbar(
          "Subscription details are not loaded yet. Please try again.",
          {
            variant: "warning",
          }
        );
        return;
      }

      const maxFileSizeBytes = subscriptionPlan.maxFileSizeMB * 1024 * 1024;

      const filteredFiles = acceptedFiles.filter((file) => {
        const fileTypeAccepted = acceptedFileTypes.includes(
          file.name.slice(file.name.lastIndexOf("."))
        );
        const fileSizeAccepted = file.size <= maxFileSizeBytes;
        if (!fileTypeAccepted) {
          enqueueSnackbar(`File type not supported: ${file.name}`, {
            variant: "error",
          });
        } else if (!fileSizeAccepted) {
          enqueueSnackbar(`File size exceeds the limit: ${file.name}`, {
            variant: "error",
          });
        }
        return fileTypeAccepted && fileSizeAccepted;
      });

      if (filteredFiles.length > 0) {
        handleFileSelect(filteredFiles[0]); // Only accept the first file
      }
    },
    [subscriptionDetails, enqueueSnackbar, handleFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(","),
    multiple: false, // Only allow a single file
  });

  const subscriptionPlan =
    subscriptionDetails?.subscriptionDetail?.subscription?.plan;
  const maxFileSizeMB = subscriptionPlan?.maxFileSizeMB;

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        sx={{
          border: "2px dotted #0000003b",
          p: { md: 8, xs: 4 },
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        <MdOutlineCloudUpload size={48} />
        {isDragActive ? (
          <p>ファイルをアップロード ...</p>
        ) : (
          <p>
            ファイルをここにドラッグ＆ドロップするか、
            クリックしてファイルを選択してください
          </p>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        {file && (
          <Typography variant="body1">
            アップロード済みのファイル {file?.name}
          </Typography>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="body2" color="textSecondary">
          最大ファイルアップロードサイズ: {maxFileSizeMB} MB
        </Typography>
      </Box>
    </div>
  );
}
