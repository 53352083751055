import React, { useEffect } from "react";
import DynamicChatList from "../../components/chat/DynamicChatList";
import MobileChatDashboard from "../../components/chat-mobile/MobileChatDashboard";
import EmptyChatbot from "./EmptyChatbot";
import { fetchSubscriptionDetails } from "../../features/chatbot/chatbotSlice";
import { useDispatch, useSelector } from "react-redux";
import SimpleBackdrop from "../../components/loader/simple-backdrop";
import { useMediaQuery } from "@mui/material";

export default function Chatbots() {
  const dispatch = useDispatch();
  const { subscriptionDetails } = useSelector((state) => state.chatbot);
  const { loading, subscriptionDetail } = subscriptionDetails;

  // Check if the screen is small (for example, <600px)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(fetchSubscriptionDetails());
  }, [dispatch]);

  return (
    <div>
      {loading && <SimpleBackdrop />}
      {subscriptionDetail?.subscription ? (
        isSmallScreen ? (
          <MobileChatDashboard />
        ) : (
          <DynamicChatList />
        )
      ) : (
        <EmptyChatbot />
      )}
    </div>
  );
}
