import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import AllFileList from "./all-file-list";
import { Typography } from "@mui/material";
import ChatbotQuestions from "./ChatbotQuestions";

export default function ChatbotSubDetailsTab({ chatbotId }) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="icon label tabs example"
            sx={{
              width: "100%",
              backgroundColor: "#ebebeb",
              borderRadius: "15px",
            }}
          >
            <Tab
              icon={<SnippetFolderIcon sx={{ fontSize: 14 }} />}
              label={<Typography sx={{ fontSize: 12 }}>ファイル</Typography>}
              value="1"
              sx={{ textTransform: "none", minHeight: 48 }}
            />
            <Tab
              icon={<LiveHelpIcon sx={{ fontSize: 14 }} />}
              label={
                <Typography sx={{ fontSize: 12 }}>クエスチョン</Typography>
              }
              value="2"
              sx={{ textTransform: "none", minHeight: 48 }}
            />
          </Tabs>
        </Box>
        <TabPanel value="1">
          <AllFileList chatbotId={chatbotId} />
        </TabPanel>
        <TabPanel value="2">
          <ChatbotQuestions chatbotId={chatbotId} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
