import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";

const initialState = {
  chatbot: {
    loading: false,
    chatbots: [],
  },

  subscriptionDetails: {
    loading: false,
    subscriptionDetail: null,
  },
  limitDetails: {
    loading: false,
    noOfFiles: null,
    maxNoOfFiles: null,
    noOfChatbot: null,
    maxNoOfChatbot: null,
    montlyPdfUpload: null,
    maxNoFileUploadMontly: null,
  },
  status: null,
  error: null,
};

// Async thunk for fetching todos

export const fetchSubscriptionDetails = createAsyncThunk(
  "chatbot/fetchSubscriptionDetails",
  async () => {
    const response = await axiosInstance.get("/user/subscription/details");
    return response.data;
  }
);

export const fetchChatbotList = createAsyncThunk(
  "chatbot/fetchChatbots",
  async () => {
    const response = await axiosInstance.get("/user/chatbot/get-list");
    return response?.data;
  }
);

export const fetchChatbotAndFileLimitDetails = createAsyncThunk(
  "chatbot/fetch-bot-file-limit-details",
  async () => {
    const response = await axiosInstance.get("/user/details/bots-files");
    return response.data;
  }
);
const chatbotSlice = createSlice({
  name: "chatbots",
  initialState,
  reducers: {
    // Sync actions can be added here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.subscriptionDetails.loading = true;
        state.status = "loading";
      })
      .addCase(fetchSubscriptionDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.subscriptionDetails.subscriptionDetail = action.payload;
        state.subscriptionDetails.loading = false;
      })
      .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
        state.status = "failed";
        state.subscriptionDetails.loading = false;
        state.error = action.error.message;
      })
      // Chatbot api
      .addCase(fetchChatbotList.pending, (state) => {
        state.chatbot.loading = true;
        state.status = "loading";
      })
      .addCase(fetchChatbotList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chatbot.chatbots = action.payload || [];
        state.chatbot.loading = false;
      })
      .addCase(fetchChatbotList.rejected, (state, action) => {
        state.status = "failed";
        state.chatbot.loading = false;
        state.error = action.error.message;
      })
      // Chatbot and file limit details
      .addCase(fetchChatbotAndFileLimitDetails.pending, (state) => {
        state.limitDetails.loading = true;
        state.status = "loading";
      })
      .addCase(fetchChatbotAndFileLimitDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        const {
          noOfFiles,
          maxNoOfFiles,
          noOfChatbot,
          maxNoOfChatbot,
          montlyPdfUpload,
          maxNoFileUploadMontly,
        } = action.payload;
        state.limitDetails =
          {
            noOfFiles,
            maxNoOfFiles,
            noOfChatbot,
            maxNoOfChatbot,
            montlyPdfUpload,
            maxNoFileUploadMontly,
            loading: false,
          } || {};
      })
      .addCase(fetchChatbotAndFileLimitDetails.rejected, (state, action) => {
        state.status = "failed";
        state.limitDetails.loading = false;
        state.error = action.error.message;
      });
  },
});

export default chatbotSlice.reducer;
